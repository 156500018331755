import * as React from "react";

const SvgIcon: React.FC<React.SVGProps<SVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g fill="#fff" clipPath="url(#clip0_6493_12397)">
      <path d="M16.052 16.026h-2.97v-.88h2.09v-2.09h.88zM2.97 16.026H0v-2.97h.88v2.09h2.09zM.88 2.944H0V-.025h2.97v.88H.88zM16.052 2.944h-.88V.854h-2.09v-.879h2.97zM13.069 13.043h-2.237v-.733h1.503v-1.503h.733zM5.219 13.043H2.983v-2.236h.733v1.503H5.22zM3.716 5.194h-.733V2.958H5.22v.733H3.716zM13.069 5.194h-.734V3.69h-1.503v-.733h2.236zM10.293 10.268H8.79v-.587h.917v-.916h.586zM7.261 10.268H5.758V8.765h.587v.916h.916zM6.345 7.236h-.587V5.732h1.503v.587h-.916zM10.293 7.236h-.586v-.917H8.79v-.587h1.503z"></path>
    </g>
    <defs>
      <clipPath id="clip0_6493_12397">
        <path fill="#fff" d="M0 0h16v16H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default SvgIcon;
